import { Model } from "@vuex-orm/core";
import Marker from "./Marker";

export default class Building extends Model {
  static entity = "aos_buildings";
  static primaryKey = "uuid";
  static aosType = "BUILDING";

  static fields() {
    return {
      uuid: this.attr(),
      label: this.attr(),
      type: this.attr(null),
      usageList: this.attr([]),
      surface: this.attr(null),
      numberOfFloors: this.number(null),
      isPresenceBasement: this.boolean(null),
      isPresenceCrawlspace: this.boolean(null),
      heatingModeList: this.attr([]),
      occupationPresence: this.attr(null),
      occupationPresentPersonList: this.attr([]),
      occupationQuantity: this.attr(null),
      isPublicReception: this.boolean(null),
      state: this.attr(null),
      soilTypeList: this.attr([]),
      slabCondition: this.attr(null),
      access: this.attr(null),
      remarks: this.attr(null),
      isAccessible: this.boolean(false),
      reason: this.attr(null),
      typology: this.attr("BUILDING"),
      pictureUuid: this.attr(null),
      tagsTypeList: this.attr([]),
      marker: this.morphOne(Marker, "markable_id", "markable_type"),
      temporality: this.attr(null),
      tracerCompoundList: this.attr([]),
      analyticalStandard: this.attr(null),
      quantificationLimit: this.attr(null),
      isSourceTakenIntoAccount: this.boolean(true),
      justification: this.attr(null),
      removalDate: this.attr(null),
    };
  }
}
