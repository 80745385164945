import { Model } from "@vuex-orm/core";
import Marker from "./Marker";

export default class Zone extends Model {
  static entity = "zones";
  static primaryKey = "uuid";
  static aosType = "ZONE";

  static fields() {
    return {
      uuid: this.attr(),
      label: this.attr(),
      usageList: this.attr([]),
      location: this.attr(null),
      waterproofSurface: this.attr(null),
      heatingModeList: this.attr([]),
      isBuildingIdenticalHeatingMode: this.boolean(false),
      occupationPresence: this.attr(null),
      occupationPresentPersonList: this.attr(null),
      occupationQuantity: this.attr(null),
      isAccessible: this.boolean(false),
      reason: this.attr(null),
      remarks: this.attr(null),
      pictureUuid: this.attr(null),
      tagsTypeList: this.attr([]),
      tracerCompoundList: this.attr([]),
      analyticalStandard: this.attr(null),
      quantificationLimit: this.attr(null),
      isSourceTakenIntoAccount: this.boolean(true),
      justification: this.attr(null),
      typology: this.attr("ZONE"),
      soilTypeList: this.attr([]),
      marker: this.morphOne(Marker, "markable_id", "markable_type"),
    };
  }
}
