import { Model } from "@vuex-orm/core";
import Marker from "./Marker";

export default class AosOutdoorSpace extends Model {
  static entity = "outdoor_spaces";
  static primaryKey = "uuid";
  static aosType = "OUTDOOR_SPACE";

  static fields() {
    return {
      uuid: this.attr(),
      label: this.attr(),
      usageList: this.attr([]),
      pictureUuid: this.attr(null),
      tagsTypeList: this.attr([]),
      tracerCompoundList: this.attr([]),
      analyticalStandard: this.attr(null),
      quantificationLimit: this.attr(null),
      isSourceTakenIntoAccount: this.boolean(true),
      justification: this.attr(null),
      typology: this.attr("OUTDOOR_SPACE"),
      details: this.attr(null),
      isPollutionTraces: this.boolean(false),
      isWaterproof: this.boolean(false),
      marker: this.morphOne(Marker, "markable_id", "markable_type"),
    };
  }
}
